<template>
    <div>
        <Navbar page="Assinar contrato" link="/contratos" nameLink="Gestão de contratos" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <h2 class="text-lg font-medium my-2">Contrato: {{ form.nome }}</h2>
                        <p class="text-base my-2">Integração para assinatura de documento não finalizada, URL temporária para fluxo geral. </p>
                        <a v-if="this.form.anexo_contrato && this.form.anexo_contrato.filename" :href="`${this.url_api}/upload?mimetype=${this.form.anexo_contrato.mimetype}&filename=${this.form.anexo_contrato.filename}&folder=empresas`" target="_blank" rel="noreferrer">
                            <span class="font-semibold">Visualizar contrato:</span>
                            <span class="ml-1 underline">{{this.form.anexo_contrato.originalname}}</span>
                        </a>
                        <button @click="save" type="button" class="block mt-5 justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Assinar
                        </button>
                    </div>
            
                </form>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            route: 'contratos',
            form: null,
        }
    },
    methods: {
        async save() {
            const req = await this.$http.post(`/v1/${this.route}/assinar`, this.form);
            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
        }
    },
}
</script>